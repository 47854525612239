
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import UserCheck from "@/views/Login/UserCheck.vue";
import { Seat } from "@/store/modules/selectSeat";
import { Row, Col, SubmitBar, Popup, Icon, Dialog } from "vant";
import EventBus from "@/utils/eventBus";

@Component({
  components: {
    UserCheck,
    [Row.name]: Row,
    [Col.name]: Col,
    [SubmitBar.name]: SubmitBar,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class SeatCart extends Mixins(Mixin) {
  // key：座位 Id；value：座位对象
  @Prop({
    type: Map,
    default() {
      return new Map();
    },
  })
  seatIdSeatMap!: Map<number, Seat>;
  @Prop({
    type: String,
    default: "",
  })
  captcha!: string;

  showCart = false;
  popupHeight = 55; // 底部弹框，高度
  popupBottom = 50; // 底部弹框，距离屏幕底部的距离
  userCheckShow = false;

  onCloseUserCheck() {
    this.userCheckShow = false;
  }
  // 人机校验对话框确定
  getUserToken(data: order.AliSliderCaptchaDto): void {
    this.$emit("submit", data);
    this.onCloseUserCheck();
  }

  @Watch("SelectSeatModule.selectedSeatIds")
  watchSelectedSeatIds(selectedSeatIds: Array<number>): void {
    this.showCart = selectedSeatIds.length > 0;
  }

  // 购物车总金额
  get totalPrice(): number {
    let selectedSeats = this.selectedSeats;
    if (!selectedSeats) {
      return 0;
    }
    let total = 0;
    selectedSeats.forEach((selectedSeat) => {
      let price = selectedSeat.price;
      if (price && price.price) {
        total += price.price;
      }
    });
    return total;
  }

  mounted(): void {
    let seatCart = this.$refs["cart-van-submit-bar"] as HTMLElement;
    let seatCartHeight = seatCart.clientHeight;
    this.popupBottom = seatCartHeight;
    if (seatCart) {
      this.SelectSeatModule.SET_cartHeight(seatCartHeight + this.popupHeight);
    }
  }

  // 已选择的座位列表
  get selectedSeats(): Array<Seat> {
    let selectedSeatIds = this.SelectSeatModule.selectedSeatIds;
    if (!selectedSeatIds) {
      return [];
    }
    let selectedSeats: Array<Seat> = [];
    selectedSeatIds.forEach((seatId) => {
      let seat = this.seatIdSeatMap.get(seatId);
      if (seat) {
        selectedSeats.push(seat);
      }
    });
    return selectedSeats.reverse();
  }

  /**
   * 删除座位
   */
  deleteSeat(seat: Seat): void {
    this.SelectSeatModule.unselectSeat(seat.seatId);
    EventBus.$emit("unselectSeatEvent", seat);
  }

  /**
   * 返回选定好的座位 ID 数组
   */
  submit(): void {
    let remarkSeatMessages: Array<string> = [];
    this.selectedSeats.forEach((selectedSeat) => {
      let remark = selectedSeat.remark;
      if (remark) {
        remarkSeatMessages.push(selectedSeat.description + " " + remark);
      }
    });
    if (remarkSeatMessages.length > 0) {
      Dialog.confirm({
        title: "提示",
        message: `<p style="text-align:center">
          ${remarkSeatMessages.join('</p><p style="text-align:center">')}
        </p>`,
      }).then(() => {
        this.doSubmit();
      });
    } else {
      this.doSubmit();
    }
  }

  doSubmit(): void {
    if (this.captcha) {
      this.userCheckShow = true;
      this.$nextTick(() => {
        let userCheck = this.$refs["user-check"] as UserCheck;
        if (userCheck) {
          userCheck.resetData();
        }
      });
    } else {
      this.SelectSeatModule.SET_submitLoading(true);
      this.$emit("submit");
    }
  }
}
