
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import EventBus from "@/utils/eventBus";
import stage01 from "@/assets/images/Seat/stage-name.png";
import stage02 from "@/assets/images/Seat/stage02.png";
import Hammer from "hammerjs";
import { Row, Seat } from "@/store/modules/selectSeat";

let contextContainer: Record<string, CanvasRenderingContext2D> = {};

@Component({ components: {} })
export default class CanvasSeats extends Mixins(Mixin) {
  // 图片缩放比例，画小地图的舞台时要用到
  @Prop({
    type: Number,
    default() {
      return 1;
    },
  })
  imageScaling!: number;
  @Prop({
    type: String,
    default() {
      return "center";
    },
  })
  showTransformOriginValue!: string;
  @Prop({
    type: String,
    default() {
      return "";
    },
  })
  showTransformValue!: string;
  @Prop({
    type: String,
    default() {
      return "";
    },
  })
  backgroundColor!: string;
  @Prop({
    type: Number,
    default() {
      return 0;
    },
  })
  containerWidth!: number;
  @Prop({
    type: Number,
    default() {
      return "";
    },
  })
  containerHeight!: number;

  supportCanvas = true; // 浏览器支持 canvas
  id = this.guid();

  seatUnselectedHighlightText = "";
  selectedSeatText = "";

  // 每个坐位单元的大小，包括字体大小和间隔大小
  get itemSize(): number {
    return this.containerWidth / this.SelectSeatModule.maxColumnCount;
  }

  // 坐位字体大小
  get fontSize(): number {
    return (this.itemSize / 6) * 4;
  }

  created(): void {
    EventBus.$on("selectSeatEvent", (seat: Seat) => {
      /**
       * 选择某个坐位
       * 因为座位图和迷你地图都需要响应这个事件
       * 所以采用事件总线的模式，避免父组件调用两个组件的方法
       * 因为是重绘画布，所以不能用绑定 vuex 的方式来动态渲染座位 Dom
       * 用 Watch 会导致一些中间状态的变化也会触发渲染，所以没有用
       */
      this.repaintSeatInternal(seat);
    });
    EventBus.$on("unselectSeatEvent", (seat: Seat) => {
      /**
       * 取消选择某个坐位
       * 因为座位图和迷你地图都需要响应这个事件
       * 所以采用事件总线的模式，避免父组件调用两个组件的方法
       * 因为是重绘画布，所以不能用绑定 vuex 的方式来动态渲染座位 Dom
       * 用 Watch 会导致一些中间状态的变化也会触发渲染，所以没有用
       */
      this.repaintSeatInternal(seat);
    });
    EventBus.$on("selectPriceEvent", (oldHighlightPriceId: number) => {
      let newHighlightPriceId = this.SelectSeatModule.highlightPriceId;
      let context = contextContainer[this.id];
      if (context && oldHighlightPriceId != newHighlightPriceId) {
        // 选择的高亮票档变化后，重新绘制画布
        context.clearRect(0, 0, this.containerWidth, this.containerHeight);
        this.paintAll(this.SelectSeatModule.rowArr);
      }
    });
  }

  handelTap(e: unknown): void {
    this.$emit("canvas-tap", e);
  }

  mounted(): void {
    let id = this.id;
    let canvas = document.querySelector(
      ".seat-box-canvas" + id
    ) as HTMLCanvasElement;
    if (canvas.getContext) {
      // 浏览器支持 canvas
      this.seatUnselectedHighlightText =
        (
          document.getElementById(
            "canvas-icon-seat-unselected-highlight" + id
          ) as HTMLElement
        ).textContent || ""; // 字体，未选中的座位
      this.selectedSeatText =
        (
          document.getElementById(
            "canvas-icon-seat-selected" + id
          ) as HTMLElement
        ).textContent || ""; // 字体，已选中的座位
      let context = canvas.getContext("2d");
      if (context) {
        contextContainer[id] = context;
      }

      let mc = new Hammer.Manager(canvas);
      mc.add(new Hammer.Pan({ threshold: 0 }));
      mc.add(new Hammer.Pinch());
      // Tap 事件，用 hammer.js 在 ios 12.4 上会丢失事件属性，因此直接绑定 tap 和 click 事件
      mc.on("panstart", (ev) => {
        this.$emit("canvas-panstart", ev);
      });
      mc.on("panmove", (ev) => {
        // 移动画布
        this.$emit("canvas-panmove", {
          ev,
          domRect: canvas.getBoundingClientRect(),
        });
      });
      mc.on("panend", (ev) => {
        this.$emit("canvas-panend", ev);
      });
      mc.on("pinchstart", (ev) => {
        this.$emit("canvas-pinchstart", ev);
      });
      mc.on("pinchmove", (ev) => {
        // 缩放画布
        this.$emit("canvas-pinchmove", {
          ev,
          domRect: canvas.getBoundingClientRect(),
        });
      });
      mc.on("pinchend", (ev) => {
        this.$emit("canvas-pinchend", {
          ev,
          domRect: canvas.getBoundingClientRect(),
        });
      });
    } else {
      // 浏览器不支持 canvas
      this.supportCanvas = false;
    }
  }

  beforeDestroy(): void {
    // 在销毁时取消监听能防止重复注册消息总线
    EventBus.$off("selectSeatEvent");
    EventBus.$off("unselectSeatEvent");
    EventBus.$off("selectPriceEvent");
    delete contextContainer[this.id]; // 防止内存泄漏
  }

  paint(): void {
    let rowArr = this.SelectSeatModule.rowArr;
    if (rowArr && rowArr.length > 0) {
      document.fonts.ready.then(() => {
        // 字体加载完成后的逻辑，不加 setTimeout 始终是不行
        setTimeout(() => {
          // 根据 rowArr 绘制整个座位图
          this.paintAll(rowArr);
          this.$emit("paint-complete");
        }, 500);
      });
    } else {
      this.clear();
    }
  }

  clear(): void {
    let context = contextContainer[this.id];
    if (context) {
      context.clearRect(0, 0, this.containerWidth, this.containerHeight);
    }
  }

  /**
   * 根据 rowArr 绘制整个座位图
   */
  paintAll(rowArr: Array<Row>): void {
    let context = contextContainer[this.id];
    if (context) {
      if (this.SelectSeatModule.isCustomStage) {
        // 后端设置了自定义舞台，前端不用绘制固定舞台
        let itemSize = this.itemSize;
        let xmin = this.SelectSeatModule.customStageXmin * itemSize;
        let xmax = this.SelectSeatModule.customStageXmax * itemSize;
        let ymin = this.SelectSeatModule.customStageYmin * itemSize;
        let ymax = this.SelectSeatModule.customStageYmax * itemSize;
        this.loadImageAndDraw(
          1,
          rowArr,
          context,
          (imageWidth) => xmin + (xmax - xmin - imageWidth) / 2,
          (imageHeight) => ymin + (ymax - ymin + imageHeight - itemSize) / 2
        );
      } else {
        // 后端没有设置自定义舞台，前端需要绘制固定舞台
        this.loadImageAndDraw(
          2,
          rowArr,
          context,
          (imageWidth) =>
            (this.itemSize * this.SelectSeatModule.maxColumnCount -
              imageWidth) /
            2,
          () => 20 * this.imageScaling
        );
      }
    }
  }

  loadImageAndDraw(
    type: number,
    rowArr: Array<Row>,
    context: CanvasRenderingContext2D,
    x: (imageWidth: number) => number,
    y: (imageHeight: number) => number
  ): void {
    let img = new Image();
    img.onload = () => {
      let imageScaling = this.imageScaling;
      let imageWidth = img.width * imageScaling;
      let imageHeight = img.height * imageScaling;
      // 绘制坐位（先绘制坐位，后绘制舞台文字）
      this.paintSeats(rowArr, context);
      // 绘制自定义舞台的文字
      context.drawImage(
        img,
        x(imageWidth),
        y(imageHeight),
        imageWidth,
        imageHeight
      );
    };
    img.src = type == 1 ? stage01 : stage02;
  }

  /**
   * 绘制坐位
   *
   * @param rowArr 坐位信息
   * @param context 画布上下文
   */
  paintSeats(rowArr: Array<Row>, context: CanvasRenderingContext2D): void {
    rowArr.forEach((row, index) => {
      if (row.isSide) {
        let itemSize = this.itemSize;
        context.fillStyle = "rgba(255, 255, 255, 0)";
        context.fillRect(0, index * itemSize, itemSize, itemSize);
      } else {
        let cols = row.cols;
        cols.forEach((seat) => {
          this.paintSeatInternal(seat, context);
        });
      }
    });
  }

  /**
   * 根据坐标绘制座位、舞台或过道
   *
   * @param seat 座位信息
   * @param context 为了避免在最细粒度的地方频繁地空值判断，采用传入 context 的方式
   */
  private paintSeatInternal(
    seat: Seat,
    context: CanvasRenderingContext2D
  ): void {
    let itemSize = this.itemSize;
    let x = seat.columnCount * itemSize; // 绘制位置 x 坐标
    let y = seat.rowCount * itemSize; // 绘制位置 y 坐标
    if (seat && seat.type == 2) {
      // 舞台
      context.fillStyle = "#ededed";
      context.fillRect(x - itemSize / 2, y, itemSize, itemSize);
    } else {
      // 座位
      let isSelected =
        this.SelectSeatModule.selectedSeatIds.indexOf(seat.seatId) >= 0;
      let seatInfo = this.getSeatColorAndOpacity(seat, isSelected);
      context.fillStyle = seatInfo.color;
      context.globalAlpha = seatInfo.opacity ? Number(seatInfo.opacity) : 1;
      context.font = this.fontSize + "px IconFont";
      context.textAlign = "center";
      context.textBaseline = "top";
      context.fillText(
        isSelected ? this.selectedSeatText : this.seatUnselectedHighlightText,
        x,
        y
      );
    }
  }

  /**
   * 重画坐位，先清除再绘制
   *
   * @param seat 坐位信息
   */
  private repaintSeatInternal(seat: Seat): void {
    let context = contextContainer[this.id];
    if (context) {
      let itemSize = this.itemSize;
      context.clearRect(
        seat.columnCount * itemSize - itemSize / 2,
        seat.rowCount * itemSize,
        itemSize,
        itemSize
      );
      this.paintSeatInternal(seat, context);
    }
  }

  /**
   * 根据坐位信息获取坐位字体文本与颜色
   *
   * @param seat 坐位信息
   * @param isSelected 是否选中
   */
  getSeatColorAndOpacity(
    seat: Seat,
    isSelected: boolean
  ): Record<string, string> {
    if (!seat || seat.seatId < 0) {
      // 过道
      return { color: "rgba(255, 255, 255, 0)" };
    }
    if (isSelected) {
      // 选中的坐位
      return { color: "#333333" };
    }
    let color = seat.available
      ? seat.price.ticketPriceColor || "#E5E5E5"
      : "#E5E5E5";
    // 未选中票档，所有坐位都高亮；选中了票档，选中的坐位高亮，没选中的置灰
    let highlightPriceId = this.SelectSeatModule.highlightPriceId;
    if (highlightPriceId < 0 || seat.price.ticketPriceId == highlightPriceId) {
      return { color };
    }
    return { color, opacity: "0.5" };
  }
}
