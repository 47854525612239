
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";

@Component
export default class UserCheck extends Mixins(Mixin) {
  uuid = this.guid();

  get divId(): string {
    return "poly-nc" + this.uuid;
  }

  resetData(): void {
    let divId = this.divId;
    let windowTemp = window as any;
    let ncKey = "nc" + divId;
    let cacheNc = windowTemp[ncKey];
    if (cacheNc) {
      cacheNc.reset();
    } else {
      windowTemp[ncKey] = (windowTemp.awscModule as any).init({
        // 应用类型标识。它和使用场景标识（scene字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
        appkey: "FFFF00000000017C3CE3",
        // 使用场景标识。它和应用类型标识（appkey字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的scene值，请务必正确填写。
        scene: "nc_login_h5",
        // 声明滑动验证需要渲染的目标ID。
        upLang: {
          cn: {
            // 加载状态提示。
            LOADING: "加载中...",
            // 等待滑动状态提示。
            SLIDE: "请按住滑块，拖到最右侧",
            // 验证通过状态提示。
            SUCCESS: "验证完成",
            // 验证失败触发拦截状态提示。
            ERROR: "非常抱歉，网络出错了...",
            // 验证失败触发拦截状态提示。
            FAIL: "验证失败，请重试",
          },
        },
        // 声明滑动验证需要渲染的目标ID。
        renderTo: divId,
        // 前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
        success: (data: any) => {
          this.$emit("success", {
            token: data.token,
            sessionId: data.sessionId,
            sig: data.sig,
          });
        },
        // 滑动验证失败时触发该回调参数。
        fail: (failCode: any) => {
          this.$emit("fail", failCode);
          window.console && console.error("滑块验证码 >>> fail", failCode);
        },
        // 验证码加载出现异常时触发该回调参数。
        error: (errorCode: any) => {
          this.$emit("fail", errorCode);
          window.console && console.error("滑块验证码 >>> error", errorCode);
        },
      });
    }
  }
}
