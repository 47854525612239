
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Seat } from "@/store/modules/selectSeat";
import CanvasSeats from "@/components/SelectSeat/SeatSelector/Seats/CanvasSeats.vue";
import EventBus from "@/utils/eventBus";

@Component({ components: { CanvasSeats } })
export default class SeatMap extends Mixins(Mixin) {
  // 演出票价信息
  @Prop({
    type: Object,
    default() {
      return {
        priceDtos: [],
      };
    },
  })
  showPrice!: good.ShowPriceDto;

  screenWidth = window.document.documentElement.clientWidth; // 屏幕宽度
  screenHeight = window.document.documentElement.clientHeight; // 屏幕高度
  initScale = 1; // 初始 scale
  lastTranslate = { x: 0, y: 0 }; // 记录上次的偏移值

  /**
   * 装画布的容器的高度，等于：屏幕高度 - 容器到顶部距离 - 购物车占用的高度
   */
  get containerHeight(): number {
    return (
      this.screenHeight -
      this.SelectSeatModule.seatSelectorRowOffsetTop -
      this.SelectSeatModule.cartHeight
    );
  }

  refresh(): void {
    this.$nextTick(() => {
      // 调整座位图的位置，使其上下左右居中
      this.fixSeatMapPosition();
      // 将座位图缩放到屏幕的宽度
      let screenWidth = this.screenWidth;
      let containerWidth = this.SelectSeatModule.canvasWidth;
      if (screenWidth < containerWidth && containerWidth > 0) {
        let initScale = this.screenWidth / containerWidth;
        this.SelectSeatModule.SET_seatMapMatrixScale(initScale);
        this.showSeatMapMatrixScale = initScale;
      }
      // 绘制座位图
      let canvasSeats = this.$refs["canvas-seats"];
      if (canvasSeats) {
        (canvasSeats as CanvasSeats).paint();
      }
    });
  }

  canvasTap(e: any): void {
    const target = e.target;
    let offsetX = e.offsetX; // 点中位置到座位图左边缘的距离，无视缩放的像素值
    let offsetY = e.offsetY; // 点中位置到座位图上边缘的距离，无视缩放的像素值
    let width = target.clientWidth; // 座位图的宽度
    let height = target.clientHeight; // 座位图的高度
    /**
     * 如果已经最大了就不用再放大了
     */
    if (this.SelectSeatModule.seatMapMatrixScale < 1) {
      this.SelectSeatModule.SET_seatMapMatrixScale(1);
      this.SelectSeatModule.SET_seatMapMatrixX(width / 2 - offsetX);
      this.SelectSeatModule.SET_seatMapMatrixY(height / 2 - offsetY);
      this.intervalUpdate();
    }
    this.clickSeat(offsetX, offsetY); // 选座
  }

  canvasPanstart(): void {
    this.SelectSeatModule.SET_isShowMiniSeatMap(true);
    this.lastTranslate = {
      x: this.SelectSeatModule.seatMapMatrixX,
      y: this.SelectSeatModule.seatMapMatrixY,
    }; // 缓存上一次的偏移值
  }

  /**
   * 移动画布
   * ev.deltaX 是相对于 panstart 时的坐标距离
   * 而不是相对于上一次移动后的坐标的距离
   * 所以需要用 lastTranslate 在 panstart 时记录一下
   *
   * @param data 手势信息和画布信息
   */
  canvasPanmove(data: Record<string, HammerInput | DOMRect>): void {
    let ev = data.ev as HammerInput;
    let domRect = data.domRect as DOMRect;
    if (
      (domRect.left < 0 || ev.deltaX < 0) && // 限制朝右边的移动，画布右边缘不要到屏幕右边缘的左边
      (domRect.right > this.screenWidth || ev.deltaX > 0) // 限制朝左边的移动，画布左边缘不要到屏幕左边缘的右边
    ) {
      this.SelectSeatModule.SET_seatMapMatrixX(
        this.lastTranslate.x + ev.deltaX
      );
      this.intervalUpdate();
    }
    if (
      (this.screenHeight - domRect.bottom < this.SelectSeatModule.cartHeight ||
        ev.deltaY > 0) && // 限制朝上边的移动，画布下边缘不要到屏幕下边缘的上边
      (domRect.top < this.SelectSeatModule.seatSelectorRowOffsetTop ||
        ev.deltaY < 0) // 限制朝下边的移动，画布上边缘不要到屏幕上边缘的下边
    ) {
      this.SelectSeatModule.SET_seatMapMatrixY(
        this.lastTranslate.y + ev.deltaY
      );
      this.intervalUpdate();
    }
  }

  canvasPanend(): void {
    this.SelectSeatModule.SET_isShowMiniSeatMap(false);
  }

  canvasPinchstart(): void {
    this.SelectSeatModule.SET_isShowMiniSeatMap(true);
    this.initScale = this.SelectSeatModule.seatMapMatrixScale || 1; // 记录上一次的缩放
  }

  /**
   * 缩放画布
   * ev.scale 是相对于 pinchstart 时的缩放比例
   * 而不是相对于上一次缩放后的大小的比例
   * 所以需要用 initScale 在 pinchstart 时记录一下
   *
   * @param data 手势信息和画布信息
   */
  canvasPinchmove(data: Record<string, HammerInput | DOMRect>): void {
    let ev = data.ev as HammerInput;
    let domRect = data.domRect as DOMRect;
    let initScale = this.initScale;
    let oldWidth = domRect.width; // 上一次 canvasPinchmove 设置的画布宽度（即本次改变前的当前画布宽度）
    let evScale = ev.scale; // 相对于 canvasPinchstart 时画布宽度的缩放比例
    let isZoomOut = evScale < 1; // 是否为缩小的情况
    if (
      (oldWidth > this.screenWidth || evScale > 1) && // 限制缩小的比例，最小缩小到屏幕宽度
      (initScale < 1 || isZoomOut) // 限制放大的比例，最大放大到 scale = 1
    ) {
      // 缩放画布
      this.SelectSeatModule.SET_seatMapMatrixScale(initScale * evScale);
      this.intervalUpdate();
    }
  }

  canvasPinchend(data: Record<string, HammerInput | DOMRect>): void {
    this.SelectSeatModule.SET_isShowMiniSeatMap(false);
    let domRect = data.domRect as DOMRect;
    if (domRect.left > 0) {
      // 通过移动保证：缩放后画布左边缘不要到屏幕左边缘的右边
      this.SelectSeatModule.SUBTRACT_seatMapMatrixX(domRect.left);
    } else if (domRect.right < this.screenWidth) {
      // 通过移动保证：缩放后画布右边缘不要到屏幕右边缘的左边
      this.SelectSeatModule.ADD_seatMapMatrixX(
        this.screenWidth - domRect.right
      );
    }
    let screenHeight = this.screenHeight; // 屏幕高度
    let seatSelectorRowOffsetTop =
      this.SelectSeatModule.seatSelectorRowOffsetTop; // 容器距离屏幕顶部的偏移
    let heightGap = this.containerHeight - domRect.height; // 容器高度减去画布高度
    if (heightGap > 0) {
      // 座位画布高度小于容器高度，上下居中
      let topGap = domRect.top - seatSelectorRowOffsetTop - heightGap / 2;
      if (topGap > 0) {
        this.SelectSeatModule.SUBTRACT_seatMapMatrixY(topGap);
      }
    } else {
      let bottomGap =
        screenHeight - domRect.bottom - this.SelectSeatModule.cartHeight; // 画布底部到容器底部的距离
      if (bottomGap > 0) {
        // 通过移动保证：缩放后画布下边缘不要到屏幕下边缘的上边
        this.SelectSeatModule.ADD_seatMapMatrixY(bottomGap);
      } else if (domRect.top > seatSelectorRowOffsetTop) {
        this.SelectSeatModule.SUBTRACT_seatMapMatrixY(
          domRect.top - this.SelectSeatModule.seatSelectorRowOffsetTop
        );
      }
    }
    this.intervalUpdate();
  }

  /**
   * 所有必要状态都需要实时更新，避免状态丢失或不一致
   * 但要把用于渲染的状态独立出来，一定时间间隔更新一次，根据实时状态更新
   * 利用 transition 和设置间隔来消除卡顿（图形渲染的状态更新太快会卡顿）
   */
  intervalUpdate(): void {
    this.antiShake(() => {
      this.showSeatMapMatrixScale = this.SelectSeatModule.seatMapMatrixScale;
      this.showSeatMapMatrixX = this.SelectSeatModule.seatMapMatrixX;
      this.showSeatMapMatrixY = this.SelectSeatModule.seatMapMatrixY;
    });
  }

  // 控制缩放和位移，真正用于渲染的样式数据（防抖更新）
  showSeatMapMatrixScale = 1;
  showSeatMapMatrixX = 0;
  showSeatMapMatrixY = 0;
  get showTransformValue(): string {
    let showSeatMapMatrixScale = this.showSeatMapMatrixScale;
    return (
      "matrix(" +
      showSeatMapMatrixScale +
      ",0,0," +
      showSeatMapMatrixScale +
      "," +
      this.showSeatMapMatrixX +
      "," +
      this.showSeatMapMatrixY +
      ")"
    );
  }

  /**
   * 选座
   *
   * @param x 点击位置距离座位图左侧的距离
   * @param y 点击位置距离座位图顶部的距离
   */
  clickSeat(x: number, y: number): void {
    let seat: Seat | null = null;
    let itemSize = this.SelectSeatModule.itemSize;
    let rowArr = this.SelectSeatModule.rowArr;
    let originX = x / itemSize;
    let columnCount = Math.round(originX);
    let originY = y / itemSize;
    let rowCount = Math.round(originY);
    if (rowCount < rowArr.length) {
      let tempRow = rowArr[rowCount];
      if (tempRow) {
        let tempCols = tempRow.cols;
        if (tempCols && columnCount < tempCols.length) {
          seat = tempCols[columnCount];
        }
      }
    }

    /**
     * 是座位，且座位可选
     *
     * 判断设置 SelectSeatModule.selectedSeatIds 的逻辑不能放到 CanvasSeats 中
     * 因为座位图和迷你地图都用了 CanvasSeats 组件，都会响应事件
     * 如果逻辑放到组件中，会导致一个事件判断并设置两次 SelectSeatModule.selectedSeatIds 而出错
     */
    if (seat && seat.type == 1 && seat.seatId != -1 && seat.available) {
      let seatId = seat.seatId;
      let selectedSeatIds = this.SelectSeatModule.selectedSeatIds;
      let surplus = this.SelectSeatModule.surplus;
      if (selectedSeatIds.indexOf(seatId) >= 0) {
        // 取消选择该座位
        this.SelectSeatModule.unselectSeat(seatId);
        // 取消选择某个坐位
        EventBus.$emit("unselectSeatEvent", seat);
      } else if (surplus > -1 && selectedSeatIds.length >= surplus) {
        // 超出可购数量限制
        this.$toast({
          message: `单场限购${surplus}张`,
        });
      } else {
        // 选座，弹出选择该座位的事件
        this.SelectSeatModule.selectSeat(seatId);
        // 选择某个坐位
        EventBus.$emit("selectSeatEvent", seat);
      }
    }
  }

  /**
   * 调整座位图的位置，使其上下左右居中
   */
  fixSeatMapPosition(): void {
    this.$nextTick(() => {
      let seatWrap = this.$refs.seatWrap as Element;
      if (seatWrap) {
        seatWrap.scrollLeft =
          (this.SelectSeatModule.itemSize *
            this.SelectSeatModule.maxColumnCount) /
            2 -
          seatWrap.clientWidth / 2;
        seatWrap.scrollTop =
          this.SelectSeatModule.canvasHeight / 2 - seatWrap.clientHeight / 2;
      }
    });
  }
}
